import React from "react";

// Components
import Buttons from "../../Components/Button/Buttons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

const ContactSectionCTA = () => {
  return (
    <m.section
      className="py-[8%] xs:py-[50px] relative bg-cover overflow-hidden bg-fixed bg-no-repeat bg-center lg:bg-local"
      style={{
        backgroundImage: "url(/assets/img/steellift-energy-group-a-propos.jpg)",
      }}
    >
      <div className="absolute h-full w-full opacity-75 top-0 left-0 bg-gradient-to-tr from-[#FF9900] via-[#663300] to-[#FFC60B]"></div>
      <Container>
        <Row className="row justify-center">
          <Col xl={7} lg={8} md={10} className="relative text-center">
            <h3 className="font-serif text-white font-semibold mb-[35px]">
              Discutons de votre projet
            </h3>
            <p className="text-white opacity-70 font-serif text-lg md:text-xmd md:leading-[22px] w-[80%] mx-auto leading-[32px] mb-[45px] sm:w-full">
              Notre équipe dévouée est prête à répondre à toutes vos questions
              et à vous guider dans vos démarches. Ne tardez pas, laissez-nous
              vous accompagner dès maintenant !
            </p>
            <Buttons
              to="/contactez-nous"
              className="btn-fill drop-shadow-md font-medium font-serif uppercase rounded-[50px] btn-shadow text-[15px] md:mb-[15px]"
              size="lg"
              themeColor="#fff"
              color="#000"
              title="Entrons en Contact"
            />
          </Col>
        </Row>
      </Container>
    </m.section>
  );
};
export default ContactSectionCTA;
