import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Link } from "react-router-dom";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Services from "../Components/Services/Services";
import Buttons from "../Components/Button/Buttons";
import { Parallax } from "react-scroll-parallax";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";
import ContactSectionCTA from "./Partial/ContactSectionCTA";

// Data
import ServicesSection from "./Partial/ServicesSection";
import LightHeader from "./Partial/LightHeader";

const portiqueData = [
  {
    img: "/assets/img/steellift-energy-portique.jpg",
    title: "Portique",
    content:
      "Les portiques sont des structures de levage robustes et flexibles, idéales pour des opérations en intérieur et extérieur, offrant une grande portée et une mobilité accrue.",
  },
  {
    img: "/assets/img/steellift-energy-semi-portique.jpg",
    title: "Semi-Portique",
    content:
      "Les semi-portiques combinent stabilité et mobilité, avec un support sur un seul côté, adaptés aux espaces restreints nécessitant une flexibilité de levage.",
  },
  {
    img: "/assets/img/steellift-energy-portique-kits.jpg",
    title: "Accessoires",
    content:
      "Nos accessoires pour portiques et semi-portiques, tels que palans et dispositifs de sécurité, optimisent la fonctionnalité et la sécurité de vos équipements de levage.",
  },
];

const PortiquePage = (props) => {
  return (
    <div style={props.style}>
      <LightHeader />
      {/* Section Start */}
      <section className="bg-lightgray py-[42px] sm:py-[30px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={8}
              lg={6}
              className="text-center font-serif flex items-center md:justify-center sm:block"
            >
              <h1 className="text-lg text-darkgray font-medium mb-0 inline-block">
                Nos Portiques
              </h1>
              <span className="pl-[25px] ml-[30px] relative -top-[1px] text-[14px] leading-[25px] block md:inline-block sm:p-0 sm:m-0 sm:mt-[5px] before:absolute before:border-l-[2px] before:border-darkgray before:h-[12px] sm:before:border-0 before:top-[7px] before:left-[-2px] sm:block">
                Des équipements de portiques adaptés à vos projets industriels
              </span>
            </Col>
            <Col
              xl={4}
              lg={6}
              className="text-center breadcrumb text-sm font-serif md:mt-[15px] justify-end md:justify-center mb-0"
            >
              <ul>
                <li>
                  <Link aria-label="link" to="/">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label="link"
                    to="/levage/nos-equipements"
                    onClick={(e) => e.preventDefault()}
                  >
                    Nos Equipements
                  </Link>
                </li>
                <li>Portiques</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                SOLUTIONS DE LEVAGE INDUSTRIEL
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Gamme Complète de Portiques et Semi-Portiques
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Chez <b>Steel Lift Energy Group</b>, nous offrons une gamme
                diversifiée de <b>portiques et semi-portiques</b> conçus pour
                répondre aux besoins variés de levage industriel. Nos solutions
                garantissent flexibilité, mobilité et performance, que ce soit
                pour des opérations en intérieur ou en extérieur. Adaptés aux
                zones de travail temporaires ou nécessitant une mobilité accrue,
                nos portiques et semi-portiques sont la réponse idéale pour vos
                exigences de manutention.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/steellift-energy-group-equipements-de-levage-portique.jpg"
                  alt="Steellift Energy Group - Nos Solutions - Nos Equipements de levage"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Des portiques robustes et fiables pour vos besoins de levage
                industriel
              </span>
              <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
                Nos solutions de portiques
              </h5>
            </Col>
          </Row>
          <Services
            grid="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center"
            theme="service-style-03"
            className=""
            data={portiqueData}
            animation={fadeIn}
          />
        </Container>
      </m.section>
      {/* Section End */}


      {/* Section start */}
      {/* 
      <m.div
        className="relative overflow-hidden py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] sm:px-[15px]"
        {...fadeIn}
      >
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[-30px] left-0 h-[100vh] lg:h-[64vh] lg:top-[-75px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-0 sm:h-[56vh] xs:h-[50vh] w-full"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-nos-solutions-de-levage.jpg)`,
          }}
        ></Parallax>
        <div className="left-0 top-0 h-full w-full absolute bg-darkslateblue opacity-75"></div>
        <Container className="relative">
          <Row className="justify-center items-center">
            <Col xl={7} md={8} sm={10} className="md:mb-[30px] sm:mb-[30px]">
              <h4 className="font-serif font-semibold text-white mb-0 sm:text-center">
                Obtenez notre catalogue complet de ponts roulants
              </h4>
            </Col>
            <Col xl={5} md={4} className="md:flex md:justify-center text-end">
              <Buttons
                ariaLabel="button"
                to="/"
                className="font-medium font-serif uppercase rounded-none lg:mb-[15px] landscape:lg:mb-[15px]"
                themeColor={["#2ed47c", "#e8a626"]}
                size="lg"
                color="#fff"
                title="Télécharger le Catalogue"
              />
            </Col>
          </Row>
        </Container>
      </m.div>
       */}
      {/* Section end */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Section Nos Equipements Start */}
      <section className="bg-ligthgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <ServicesSection />
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default PortiquePage;
