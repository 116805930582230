import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Link } from "react-router-dom";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Services from "../Components/Services/Services";
import Buttons from "../Components/Button/Buttons";
import { Parallax } from "react-scroll-parallax";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";
import ContactSectionCTA from "./Partial/ContactSectionCTA";

// Data
import ServicesSection from "./Partial/ServicesSection";
import LightHeader from "./Partial/LightHeader";

const pontRoulantData = [
  {
    img: "/assets/img/steellift-energy-pont-roulant-mono-poutre.jpg",
    title: "Pont roulant monopoutre",
    content:
      "Idéal pour des charges moyennes, offrant une solution économique et efficace pour les applications industrielles.",
  },
  {
    img: "/assets/img/steellift-energy-pont-roulant-bipoutre.jpg",
    title: "Pont roulant bipoutre",
    content:
      "Conçu pour des charges très lourdes et des portées plus grandes, assurant robustesse et stabilité optimale.",
  },
  {
    img: "/assets/img/steellift-energy-pont-kit-roulant.jpg",
    title: "Kit de pont roulant",
    content:
      "Fournit tous les composants nécessaires pour assembler un pont roulant adapté à vos besoins spécifiques.",
  },
];

const PontRoulantPage = (props) => {
  return (
    <div style={props.style}>
      <LightHeader />
      {/* Section Start */}
      <section className="bg-lightgray py-[42px] sm:py-[30px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={8}
              lg={6}
              className="text-center font-serif flex items-center md:justify-center sm:block"
            >
              <h1 className="text-lg text-darkgray font-medium mb-0 inline-block">
                Ponts Roulants
              </h1>
              <span className="pl-[25px] ml-[30px] relative -top-[1px] text-[14px] leading-[25px] block md:inline-block sm:p-0 sm:m-0 sm:mt-[5px] before:absolute before:border-l-[2px] before:border-darkgray before:h-[12px] sm:before:border-0 before:top-[7px] before:left-[-2px] sm:block">
                Lifting Excellence avec nos Ponts Roulants
              </span>
            </Col>
            <Col
              xl={4}
              lg={6}
              className="text-center breadcrumb text-sm font-serif md:mt-[15px] justify-end md:justify-center mb-0"
            >
              <ul>
                <li>
                  <Link aria-label="link" to="/">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label="link"
                    to="/levage/nos-equipements"
                    onClick={(e) => e.preventDefault()}
                  >
                    Nos Equipements
                  </Link>
                </li>
                <li>Ponts Roulants</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Solutions de Levage Industriel
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Solutions Complètes en Ponts Roulants
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Chez <b>Steel Lift Energy Group</b>, nous proposons une gamme
                complète et sur mesure des <b>ponts roulants</b> adaptés à
                divers besoins de levage industriel. Conçus pour offrir
                robustesse, sécurité et efficacité, nos ponts roulants sont
                idéaux pour optimiser vos opérations de manutention, qu'il
                s'agisse de charges légères ou lourdes.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <video
                  className="rounded-[6px]  w-full h-auto min-h-[436px] max-h-[436px] object-cover"
                  src="/assets/video/steellift-energy-group-nos-solutions-equipements.mp4"
                  autoPlay
                  loop
                  muted
                >
                  Your browser does not support the video tag.
                </video>
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "35px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Découvrez notre gamme complète de ponts roulants
              </span>
              <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
                Solutions Innovantes de Ponts Roulants
              </h5>
            </Col>
          </Row>
          <Services
            grid="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center"
            theme="service-style-03"
            className=""
            data={pontRoulantData}
            animation={fadeIn}
          />
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section start */}
      {/* 
      <m.div
        className="relative overflow-hidden py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] sm:px-[15px]"
        {...fadeIn}
      >
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[-30px] left-0 h-[100vh] lg:h-[64vh] lg:top-[-75px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-0 sm:h-[56vh] xs:h-[50vh] w-full"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-nos-solutions-de-levage.jpg)`,
          }}
        ></Parallax>
        <div className="left-0 top-0 h-full w-full absolute bg-darkslateblue opacity-75"></div>
        <Container className="relative">
          <Row className="justify-center items-center">
            <Col xl={7} md={8} sm={10} className="md:mb-[30px] sm:mb-[30px]">
              <h4 className="font-serif font-semibold text-white mb-0 sm:text-center">
                Obtenez notre catalogue complet de ponts roulants
              </h4>
            </Col>
            <Col xl={5} md={4} className="md:flex md:justify-center text-end">
              <Buttons
                ariaLabel="button"
                to="/"
                className="font-medium font-serif uppercase rounded-none lg:mb-[15px] landscape:lg:mb-[15px]"
                themeColor={["#2ed47c", "#e8a626"]}
                size="lg"
                color="#fff"
                title="Télécharger le Catalogue"
              />
            </Col>
          </Row>
        </Container>
      </m.div>
       */}
      {/* Section end */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Section Nos Equipements Start */}
      <section className="bg-ligthgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <ServicesSection />
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default PontRoulantPage;
