import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Parallax } from "react-scroll-parallax";

// Components
import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from "../../Components/Button/Buttons";
import Overlap from "../../Components/Overlap/Overlap";
import FooterStyle03 from "../../Components/Footers/FooterStyle03";
import CustomModal from "../../Components/CustomModal";

const DefaultFooter = () => {
  return (
    <>
      {/* Parallax Scrolling Start */}
      <div className="h-[600px] md:h-[450px] sm:h-[350px] bg-top overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-50, 50]}
          style={{
            backgroundImage: `url(/assets/img/JMTI-Energy-Africa.jpg)`,
          }}
        ></Parallax>
      </div>
      {/* Parallax Scrolling End */}

      <m.section className="bg-[#262b35] sm:bg-lightgray" {...fadeIn}>
        <Container className="sm:pb-[50px]">
          <Row className="xs:mx-0">
            <Overlap className="relative bg-white rounded-[5px] px-[0px] py-[65px] md:p-[40px] xs:px-[20px] flex sm:flex sm:justify-center sm:items-center sm:flex-col">
              <Col
                lg={{ span: 5, offset: 1 }}
                md={7}
                sm={8}
                className="text-start sm:mb-[20px]"
              >
                <h5 className="font-serif font-medium text-darkgray w-[95%] mb-0 lg:w-full sm:text-center">
                  En Afrique, nous maintenons notre engagement sur le continent
                </h5>
              </Col>
              <Col
                lg={{ span: 4, offset: 2 }}
                md={5}
                sm={8}
                className="ourservice-start-your-project text-center flex items-center md:text-start sm:justify-center"
              >
                {/* Modal Component Start */}
                <CustomModal.Wrapper
                  modalBtn={
                    <span className="inline-flex flex-row items-center justify-center">
                      <Buttons
                        href="/steel-lift-afrique"
                        className="font-semibold font-serif p-0 uppercase text-xmd btn-link after:h-[1px] md:text-md mb-0 after:bg-darkgray"
                        size="xlg"
                        color="#232323"
                        title="En Savoir Plus"
                      />
                    </span>
                  }
                >
                  <div className="w-[1020px] max-w-full relative rounded mx-auto">
                    <div className="fit-video">
                      <iframe
                        width="100%"
                        height="100%"
                        className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                        controls
                        src="https://www.youtube.com/watch?v=04ghsj903rg?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </CustomModal.Wrapper>
                {/* Modal Component End */}
              </Col>
            </Overlap>
          </Row>
        </Container>
        {/* Footer Start */}

        {/* footer Start */}
        <FooterStyle03 theme="dark" className="text-slateblue bg-[#262b35]" />
        {/* footer End */}
      </m.section>
    </>
  );
};

export default DefaultFooter;
