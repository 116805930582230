import React from "react";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";

// Libraries
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { SwiperSlide, Swiper } from "swiper/react";
import { Keyboard } from "swiper";

// Partials
import EngineeringSection from "./Partial/EngineeringSection";
import DefaultFooter from "./Partial/DefaultFooter";
import EquipementsSection from "./Partial/EquipementsSection";
import LightHeader from "./Partial/LightHeader";

// Data
const swiperData = [
  {
    img: "/assets/img/steellift-energy-group-solutions-services-installation.jpg",
    title: "Mise en service",
    content:
      "Nous réalisons l'installation et la configuration complète de vos équipements de levage, garantissant une performance optimale et une opération sécurisée dès le premier jour.",
  },
  {
    img: "/assets/img/steellift-energy-group-solutions-services-controle.jpg",
    title: "Contrôle qualité",
    content:
      "Vérification rigoureuse des équipements pour assurer leur conformité aux normes de sécurité et de performance.",
  },
  {
    img: "/assets/img/steellift-energy-group-solutions-services-audit.jpg",
    title: "Audit et conseil",
    content:
      "Analyse approfondie de vos installations de levage avec recommandations pour améliorer l'efficacité et la sécurité.",
  },
  {
    img: "/assets/img/steellift-energy-group-solutions-services-maintenance.jpg",
    title: "Maintenance et réparation",
    content:
      "Entretien régulier et interventions rapides pour maintenir vos équipements de levage en parfait état de fonctionnement.",
  },
  {
    img: "/assets/img/steellift-energy-group-solutions-services-etude.jpg",
    title: "Etudes et conception",
    content:
      "Conception personnalisée et études techniques pour adapter les solutions de levage à vos besoins spécifiques.",
  },
];

const OurServicesPage = (props) => {
  const swiperRef = React.useRef(null);
  return (
    <div style={props.style}>
    
      <LightHeader />
      {/* Section Start */}
      <section className="bg-lightgray py-[42px] sm:py-[30px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={8}
              lg={6}
              className="text-center font-serif flex items-center md:justify-center sm:block"
            >
              <h1 className="text-lg text-darkgray font-medium mb-0 inline-block">
                Services Professionnels
              </h1>
              <span className="pl-[25px] ml-[30px] relative -top-[1px] text-[14px] leading-[25px] block md:inline-block sm:p-0 sm:m-0 sm:mt-[5px] before:absolute before:border-l-[2px] before:border-darkgray before:h-[12px] sm:before:border-0 before:top-[7px] before:left-[-2px] sm:block">
                Découvrez notre gamme de services professionnels
              </span>
            </Col>
            <Col
              xl={4}
              lg={6}
              className="text-center breadcrumb text-sm font-serif md:mt-[15px] justify-end md:justify-center mb-0"
            >
              <ul>
                <li>
                  <Link aria-label="link" to="/">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label="link"
                    to="/levage"
                    onClick={(e) => e.preventDefault()}
                  >
                    Nos Solutions
                  </Link>
                </li>
                <li>Services Professionnels</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r  from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Nos Services Complémentaires en levage
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Pour une Performance Optimale
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Notre offre inclut des consultations spécialisées, des analyses
                personnalisées, et des formations pour garantir une utilisation
                optimale de vos équipements. Nous assurons également une
                assistance technique et des services de maintenance préventive
                pour assurer la performance et la durabilité de vos systèmes de
                levage. Ces services sont conçus pour compléter et renforcer nos
                solutions de levage, vous offrant un soutien complet tout au
                long de votre projet.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/steellift-energy-group-solutions-service-qualite.jpg"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        id="services"
        className="bg-lightgray relative py-[130px] lg:py-[90px] sm:py-[75px] xs:py-[50px]"
        {...fadeIn}
      >
        <Parallax
          className="lg-no-parallax bg-cover absolute -top-[50px] left-0 w-full h-[100vh]"
          translateY={[-50, 50]}
          style={{
            backgroundImage: `url(/assets/img/webp/our-services-17.webp)`,
          }}
        ></Parallax>
        <Container>
          <Row>
            <Col xl={7} lg={8} md={12} className="md:mb-[40px]">
              <Row>
                <Col className="relative mb-12 lg:mb-[40px] sm:mb-[7.5rem] xs:mb-[4.5rem]">
                  <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                    Services Connexes à Nos Solutions
                  </span>
                  <h5 className="font-serif font-semibold text-darkgray">
                    Soutien Continu et Expertise Technique
                  </h5>
                  <p className="w-[80%] mb-[4.5rem] md:w-full">
                    Nous offrons un soutien complet avec des consultations
                    spécialisées, des analyses personnalisées et des formations
                    pour maximiser l’utilisation de vos équipements. Notre
                    assistance technique et notre maintenance préventive
                    garantissent la performance et la durabilité de vos systèmes
                    de levage, assurant ainsi un soutien constant tout au long
                    de votre projet.
                  </p>
                  <div className="flex">
                    <div
                      onClick={() => swiperRef.current.swiper.slidePrev()}
                      className="btn-slider-next transition-default rounded-full flex justify-center items-center text-black bg-transparent border-[1px] border-mediumgray right-inherit left-[65px] h-[40px] w-[40px] cursor-pointer hover:shadow-[0_0_20px_rgba(0,0,0,0.15)] hover:border-white hover:bg-white"
                    >
                      <button className="text-xmd leading-none">
                        <i className="feather-arrow-left"></i>
                      </button>
                    </div>
                    <div
                      onClick={() => swiperRef.current.swiper.slideNext()}
                      className="btn-slider-prev transition-default rounded-full flex justify-center items-center text-black right-inherit border-[1px] border-mediumgray h-[40px] w-[40px] ml-[10px] cursor-pointer hover:shadow-[0_0_20px_rgba(0,0,0,0.15)] hover:border-white hover:bg-white"
                    >
                      <button className="text-xmd leading-none">
                        <i className="feather-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Swiper
                    ref={swiperRef}
                    effect="slide"
                    modules={[Keyboard]}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    slidesPerView={1}
                    loop={true}
                  >
                    {swiperData.map((item, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <div className="border border-mediumgray rounded-[4px] overflow-hidden mr-[5px] xs:mr-0">
                            <Row className="g-0 row-cols-1 row-cols-sm-2">
                              <Col
                                className="cover-background xs:h-[250px]"
                                style={{ backgroundImage: `url(${item.img})` }}
                              ></Col>
                              <Col>
                                <div className="p-16 lg:p-12">
                                  <span className="font-serif text-darkgray font-medium inline-block mb-[15px] text-xmd">
                                    {item.title}
                                  </span>
                                  <p className="mb-[25px]">{item.content}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={12} xl={{ offset: 1 }}>
              <div className="sticky top-0">
                <div className="ourservice-list relative bg-gradient-to-bl from-[#231F20] to-[#FFC60B] w-full overflow-hidden rounded-[4px] p-16 lg:p-12 md:p-16">
                  <i className="line-icon-Gears text-[11rem] leading-[11rem]  text-darkgray opacity-20 absolute -top-[20px] -left-[30px]"></i>
                  <h6 className="font-serif font-medium text-white mb-[35px] sm:mb-[15px] relative z-[1]">
                    Services Complémentaires à Nos Solutions
                  </h6>
                  <ul className="list-style-03 font-serif">
                    <li className="border-[#ffffff33] text-white !py-[10px] px-0 border-b mb-0">
                      Mise en service
                    </li>
                    <li className="border-[#ffffff33] text-white !py-[10px] px-0 border-b mb-0">
                      Contrôle qualité
                    </li>
                    <li className="border-[#ffffff33] text-white !py-[10px] px-0 border-b mb-0">
                      Audit et conseil
                    </li>
                    <li className="border-[#ffffff33] text-white !py-[10px] px-0 border-b mb-0">
                      Maintenance et réparation
                    </li>
                    <li className="border-[#ffffff33] text-white !py-[10px] px-0 border-b mb-0 pb-0">
                      Etudes et conception
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <EquipementsSection />
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-lightgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <EngineeringSection />
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default OurServicesPage;
