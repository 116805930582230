import React, { lazy } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { m } from "framer-motion";
import { TextAnime } from "../../Components/FancyText/FancyText";

// Components
import Lists from "../../Components/Lists/Lists";
import { fadeIn, fadeInLeft } from "../../Functions/GlobalAnimations";

// Data
import { ListData01 } from "../../Components/Lists/ListsData";
const TestimonialsCarouselData = [
  {
    img: "/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Charpente.jpg",
    title: "Charpente Métallique",
    number: "01",
    content: "Conception et installation de structures métalliques robustes.",
  },
  {
    img: "/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Tuyauterie.jpg",
    title: "Tuyauterie Industrielle",
    number: "02",
    content: "Installation et maintenance de systèmes de tuyauterie complexes.",
  },
  {
    img: "/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Chaudronnerie.jpg",
    title: "Chaudronnerie Industrielle",
    number: "03",
    content:
      "Fabrication et réparation d'équipements en métal de haute qualité.",
  },
  {
    img: "/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Convoyeurs.jpg",
    title: "Mécanique des convoyeurs",
    number: "04",
    content: "Maintenance et optimisation des systèmes de convoyage.",
  },
  {
    img: "/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Usine-PBA.jpg",
    title: "Usine de fabrication de PBA",
    number: "05",
    content: "Production de poteaux en béton armé pour diverses applications.",
  },
];

const PrestationsSection = (props) => {
  const swiperRef = React.useRef(null);
  return (
    <Container>
      <Row className="items-center justify-center">
        <Col lg={6} md={9} className="p-0 md:mb-[30px]">
          <m.div className="relative" {...fadeIn}>
            <div
              onClick={() => swiperRef.current.swiper.slidePrev()}
              className="btn-slider-prev bg-darkgray z-[11] text-[#fff] w-[50px] h-[50px] left-[15px] transition-default leading-[40px] m-0 right-auto absolute top-[47%] flex item-center justify-center hover:bg-white hover:text-darkgray  cursor-pointer"
            >
              <button className="text-xmd" aria-label="prev btn">
                <i className="feather-arrow-left"></i>
              </button>
            </div>

            <div
              onClick={() => swiperRef.current.swiper.slideNext()}
              className="btn-slider-next btn-slider-next bg-darkgray z-[11] text-[#fff] w-[50px] transition-default h-[50px] right-[15px] leading-[40px] m-0 left-auto absolute top-[47%] flex item-center justify-center hover:bg-white hover:text-darkgray  cursor-pointer"
            >
              <button className="text-xmd" aria-label="next btn">
                <i className="feather-arrow-right"></i>
              </button>
            </div>
            <Swiper
              className="black-move swiper-pagination-medium h-full"
              ref={swiperRef}
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              {TestimonialsCarouselData.map((item, i) => {
                return (
                  <SwiperSlide key={i} className="p-[15px]">
                    <div className="h-full bg-[#fff] box-shadow">
                      <img
                        className="w-full"
                        src={item.img}
                        alt="TestimonialsCarousel"
                        width={555}
                        height={432}
                      />
                      <div className="items-center justify-center text-center flex px-[4.5rem] py-14 flex-row	xs:p-8">
                        <div className="grow-0 shrink-0 basis-auto flex-initial pr-[40px] xs:pr-[15px]">
                          <h2 className="heading-4 font-semibold text-darkgray font-serif mb-0">
                            {item.number}
                          </h2>
                        </div>
                        <div className="overflow-hidden max-w-full pl-[40px] border-basecolor border-l-[2px] text-left xs:pl-[15px]">
                          <span className="mb-[5px] font-medium text-darkgray text-xmd font-serif block">
                            {item.title}
                          </span>
                          <p className="mb-0">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </m.div>
        </Col>
        <m.div className="col-lg-5 col-md-9 offset-lg-1" {...fadeInLeft}>
          <h2 className="heading-4 mb-[40px] leading-[40px] font-semibold tracking-[-1px] text-darkgray font-serif xs:mb-[20px]">
            <TextAnime
              duration={3000}
              color="#232323"
              className="font-semibold animated-text-black"
              animation="slide"
              data={["Découvrez", "Explorez", "Parcourez"]}
            />
            <span className="p-0 block">Nos prestations industrielles</span>
          </h2>
          <p className="w-[90%] mb-[30px] xs:w-full">
            Découvrez nos prestations industrielles conçues pour répondre aux
            exigences les plus élevées en matière de levage, d'ingénierie, et de
            fabrication. Notre expertise et notre engagement envers l'innovation
            nous permettent de vous offrir des solutions fiables et sur mesure
            pour tous vos projets.
          </p>
          <m.div className="col w-[85%] xs:w-full">
            <Lists
              className="font-medium"
              theme="list-style-01"
              data={ListData01}
            />
          </m.div>
        </m.div>
      </Row>
    </Container>
  );
};
export default PrestationsSection;
