import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Link } from "react-router-dom";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";
import ServicesSection from "./Partial/ServicesSection";
import ContactSectionCTA from "./Partial/ContactSectionCTA";
import EquipementsSection from "./Partial/EquipementsSection";
import LightHeader from "./Partial/LightHeader";

const EquipmentsPage = (props) => {
  return (
    <div style={props.style}>
      <LightHeader />
      {/* Section Start */}
      <section className="bg-lightgray py-[42px] sm:py-[30px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={8}
              lg={6}
              className="text-center font-serif flex items-center md:justify-center sm:block"
            >
              <h1 className="text-lg text-darkgray font-medium mb-0 inline-block">
                Nos Equipements
              </h1>
              <span className="pl-[25px] ml-[30px] relative -top-[1px] text-[14px] leading-[25px] block md:inline-block sm:p-0 sm:m-0 sm:mt-[5px] before:absolute before:border-l-[2px] before:border-darkgray before:h-[12px] sm:before:border-0 before:top-[7px] before:left-[-2px] sm:block">
                Nos Équipements de Levage Industriel
              </span>
            </Col>
            <Col
              xl={4}
              lg={6}
              className="text-center breadcrumb text-sm font-serif md:mt-[15px] justify-end md:justify-center mb-0"
            >
              <ul>
                <li>
                  <Link aria-label="link" to="/">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label="link"
                    to="/nos-solutions"
                    onClick={(e) => e.preventDefault()}
                  >
                    Nos Solutions
                  </Link>
                </li>
                <li>Nos Equipements</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Solutions de Levage Industriel
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Expertise et Équipements de Pointe
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Chez <b>Steel Lift Energy Group</b>, nous offrons des solutions
                de levage industriel sécurisées, précises et efficaces. Grâce à
                nos équipements de pointe et notre expertise, nous répondons à
                vos besoins en déplacement de charges lourdes et en installation
                de structures complexes. Faites confiance à notre savoir-faire
                pour optimiser vos opérations de levage.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/steellift-energy-group-nos-solutions-equipements.jpg"
                  alt="Steellift Energy Group - Nos Solutions - Nos Equipements de levage"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Nos Equipements Start */}
      <section className="py-[130px] lg:py-[95px] md:py-[70px] sm:py-[50px]py-[130px] sm:py-[50px] bg-lightgray">
        <EquipementsSection />
      </section>
      {/* Section End */}

      {/* Section Nos Equipements Start */}
      <section className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <ServicesSection />
      </section>
      {/* Section End */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default EquipmentsPage;
