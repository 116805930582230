import React, { lazy } from "react";

// Components
import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from "../../Components/Button/Buttons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

// Data
const IconWithTextData = [
  {
    icon: "line-icon-Gear-2 text-[#b7b7b7] text-[40px]",
    title: "Mise en service",
    content:
      "Installation et optimisation de vos équipements de levage dès le premier jour.",
  },
  {
    icon: "line-icon-Money-Bag text-[#b7b7b7] text-[40px]",
    title: "Contrôle & Audit",
    content:
      "Évaluation rigoureuse pour garantir la conformité et l’efficacité de vos installations.",
  },
  {
    icon: "line-icon-Cursor-Click2 text-[#b7b7b7] text-[40px]",
    title: "Maintenance & Réparation",
    content:
      "Services pour prolonger la durée de vie de vos équipements et minimiser les temps d'arrêt.",
  },
  {
    icon: "line-icon-Talk-Man text-[#b7b7b7] text-[40px]",
    title: "Etudes & Ingénierie",
    content:
      "Solutions personnalisées et innovantes pour répondre aux défis spécifiques de vos projets.",
  },
];

const IconWithText = lazy(() =>
  import("../../Components/IconWithText/IconWithText")
);

const ServicesSection = () => {
  return (
    <Container>
      <Row className="justify-center">
        <m.div
          className="col-xl-3 col-lg-4 col-sm-7 flex flex-col md:mb-24"
          {...{ ...fadeIn, transition: { delay: 0.2 } }}
        >
          <div className="mb-[20px] md:text-center sm:mb-[10px]">
            <span className="font-serif text-md uppercase font-medium text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]">
              Nos Panoplies de Services
            </span>
          </div>
          <h3 className="alt-font text-darkgray font-semibold mb-[20px] font-serif md:text-center md:mb-[30px] heading-5">
            Une gamme complète de services en levage industriel
          </h3>
          <div className="mt-auto mx-auto mx-lg-0">
            <Buttons
              href="/levage/nos-services"
              className="font-medium font-serif uppercase bg-[#fff] text-[#000] hover:bg-black rounded-none md:mb-[15px] text-xxs btn-fancy xs:mb-0"
              size="sm"
              title="En Savoir Plus"
            />
          </div>
        </m.div>
        <Col xl={{ span: 7, offset: 2 }} lg={8}>
          <IconWithText
            grid="row-cols-1 row-cols-lg-2 row-cols-sm-2 gap-y-[40px]"
            theme="icon-with-text-01"
            data={IconWithTextData}
            animation={fadeIn}
            animationDelay={0.2}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesSection;
