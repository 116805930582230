import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { m } from "framer-motion";
import { Link } from "react-router-dom";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";
import ContactSectionCTA from "./Partial/ContactSectionCTA";

// Data
import EngineeringSection from "./Partial/EngineeringSection";
import LightHeader from "./Partial/LightHeader";


const ChauderoneriePage = (props) => {
  return (
    <div style={props.style}>

      <LightHeader />

      {/* Section Start */}
      <section className="bg-lightgray py-[42px] sm:py-[30px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={8}
              lg={6}
              className="text-center font-serif flex items-center md:justify-center sm:block"
            >
              <h1 className="text-lg text-darkgray font-medium mb-0 inline-block">
                Chaudronnerie Industrielle
              </h1>
              <span className="pl-[25px] ml-[30px] relative -top-[1px] text-[14px] leading-[25px] block md:inline-block sm:p-0 sm:m-0 sm:mt-[5px] before:absolute before:border-l-[2px] before:border-darkgray before:h-[12px] sm:before:border-0 before:top-[7px] before:left-[-2px] sm:block">
                Expertise en chaudronnerie industrielle
              </span>
            </Col>
            <Col
              xl={4}
              lg={6}
              className="text-center breadcrumb text-sm font-serif md:mt-[15px] justify-end md:justify-center mb-0"
            >
              <ul>
                <li>
                  <Link aria-label="link" to="/">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label="link"
                    to="/nos-prestations-industrielles"
                    onClick={(e) => e.preventDefault()}
                  >
                    Nos Prestations
                  </Link>
                </li>
                <li>Chaudronnerie</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Excellence en Chaudronnerie Industrielle
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Expertise Supérieure en Chaudronnerie Métallique
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Nous offrons une expertise inégalée en chaudronnerie
                industrielle, spécialisée dans la fabrication et la réparation
                de structures métalliques complexes. Grâce à notre savoir-faire
                technique et à l'utilisation de matériaux de premier choix, nous
                garantissons des solutions robustes, précises et conformes aux
                normes les plus strictes. Notre engagement envers l'excellence
                assure la durabilité et la fiabilité de chaque projet.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/JMTI-Energy-Nos-Prestations-Industrielles-Chaudronnerie.jpg"
                  alt="Steellift Energy Group - Nos Solutions - Nos Equipements de levage"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* <section className="bg-lightgray border-bottom border-color-extra-light-gray px-[10%] lg:px-0 py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container fluid>
          <Row className="justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Découvrez nos projets de chaudronnerie industrielle réalisés
                avec expertise et précision
              </span>
              <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
                Nos Réalisations en Chaudronnerie
              </h5>
            </Col>
          </Row>
          <Row>
            <ImageGallery
              theme="image-gallery-02"
              grid="grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-large"
              data={imageGalleryData02}
              overlay={["#36c1e1", "#28ccc0", "#74cf8e", "#bac962", "#feb95b"]}
              animation={fadeIn}
            />
          </Row>
        </Container>
      </section> */}

      {/* Section Start */}
      <section className="bg-lightgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <EngineeringSection />
      </section>
      {/* Section End */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default ChauderoneriePage;
