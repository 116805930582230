const JmtiHeaderData = [
  {
    title: "Accueil",
    link: "/",
  },
  {
    title: "A propos",
    link: "/a-propos-de-nous",
  },
  {
    title: "Levage",
    link: "/levage",
    dropdown: [
      {
        title: "Types d'équipements",
        link: "/levage/nos-equipements",
        dropdown: [
          {
            title: "Ponts roulants",
            link: "/levage/nos-equipements/ponts-roulants",
          },
          {
            title: "Portiques",
            link: "/levage/nos-equipements/portiques",
          },
          {
            title: "Potences",
            link: "/levage/nos-equipements/potences",
          },
        ],
      },
      {
        title: "Services",
        link: "/levage/nos-services",
      },
    ],
  },
  {
    title: "Nos Prestations",
    link: "/nos-prestations-industrielles",
    dropdown: [
      {
        title: "Charpente métallique",
        link: "/nos-prestations-industrielles/charpente-et-menuiserie",
      },
      {
        title: "Tuyauterie",
        link: "/nos-prestations-industrielles/tuyauterie",
      },
      {
        title: "Chaudronnerie",
        link: "/nos-prestations-industrielles/chaudronnerie",
      },
      {
        title: "Convoyeurs",
        link: "/nos-prestations-industrielles/convoyeurs",
      },
      {
        title: "Usine de fabrication PBA",
        link: "/nos-prestations-industrielles/usine-fabrication-pba",
      },
    ],
  },
  {
    title: "Etudes & Ingénierie",
    link: "/etudes-et-ingenierie",
  },
  {
    title: "Contact",
    link: "/contactez-nous",
  },
];

export default JmtiHeaderData;
