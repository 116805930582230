const ProcessStepData01 = [
  {
    icon: "line-icon-Idea-5",
    title: "Conception",
    content:
      "Nous développons des solutions techniques sur mesure basées sur une compréhension approfondie de vos besoins et exigences.",
  },
  {
    icon: "line-icon-Fountain-Pen",
    title: "Dossier d'étude",
    content:
      "Nous préparons des dossiers d'étude complets, incluant toutes les analyses nécessaires pour garantir la faisabilité et l'efficacité du projet.",
  },
  {
    icon: "line-icon-Loading-2",
    title: "Etablissement des plans",
    content:
      "Nous créons des plans détaillés et précis pour guider la mise en œuvre, assurant que chaque aspect du projet est clairement défini.",
  },
  {
    icon: "line-icon-Juice",
    title: "Assistance à MOE",
    content:
      "Nous fournissons une assistance technique tout au long du processus de maîtrise d'œuvre, garantissant une exécution fluide et conforme aux spécifications.",
  },
];

const ProcessStepData02 = [
  {
    title: "Project research",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
  {
    title: "Sketches and design",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
  {
    title: "Seleted concept",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
];

const ProcessStepData03 = [
  {
    title: "RESEARCH",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "SKETCHES",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "CONCEPT",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "PRESENTATION",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
];

const ProcessStepData04 = [
  {
    title: "RESEARCH PROJECT",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "FIND PROBLEM",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "START OPTIMIZE",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "REACH TARGET",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
];

const ProcessStepData05 = [
  {
    title: "Start market research",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Discussion of the idea",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Production planning",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
];

const ProcessStepData06 = [
  {
    title: "Innovative business",
    content: "Easy to customize",
  },
  {
    title: "Expertly marketing",
    content: "High quality services",
  },
  {
    title: "Engaging audiences",
    content: "Build perfect websites",
  },
];

export {
  ProcessStepData01,
  ProcessStepData02,
  ProcessStepData03,
  ProcessStepData04,
  ProcessStepData05,
  ProcessStepData06,
};
