import React from "react";

// Libraries
import { m } from "framer-motion";

// Components
import { fadeIn } from "../../Functions/GlobalAnimations";
import FooterStyle03 from "../../Components/Footers/FooterStyle03";

const AfricaFooter = () => {
  return (
    <>
      <m.section className="bg-[#262b35] sm:bg-lightgray" {...fadeIn}>
        {/* footer Start */}
        <FooterStyle03 theme="dark" className="text-slateblue bg-[#262b35]" />
        {/* footer End */}
      </m.section>
    </>
  );
};

export default AfricaFooter;
