import React from "react";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";
import ProcessStep from "../Components/ProcessStep/ProcessStep";
import Services from "../Components/Services/Services";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";

// Data
import { ProcessStepData01 } from "../Components/ProcessStep/ProcessStepData";
import ContactSectionCTA from "./Partial/ContactSectionCTA";
import DarkHeader from "./Partial/DarkHeader";

const ActivitiesData = [
  {
    img: "/assets/img/steellift-energy-group-ingenierie-etudes.jpg",
    title: "Etudes",
    content:
      "Nous réalisons des analyses approfondies et des études techniques pour concevoir des solutions adaptées à vos besoins spécifiques.",
  },
  {
    img: "/assets/img/steellift-energy-group-ingenierie-assitance.jpg",
    title: "Assistance",
    content:
      "Nous fournissons un soutien technique tout au long de vos projets, garantissant une mise en œuvre réussie et une optimisation des performances.",
  },
  {
    img: "/assets/img/steellift-energy-group-ingenierie-formation.jpg",
    title: "Formations",
    content:
      "Nous offrons des formations spécialisées pour vous permettre de maîtriser nos solutions et d'assurer une utilisation efficace de vos équipements.",
  },
];

const IngineeringPage = (props) => {
  return (
    <div style={props.style}>
      <DarkHeader />
      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-etude-ingenierie-industrielle.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={6} lg={7} sm={9} className="relative text-center">
              <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                Des études techniques approfondies et une ingénierie de pointe
              </h1>
              <h2 className="font-serif text-white -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                Études et Ingénierie
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:bottom-7 xs:bottom-[4.5rem] cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Notre bureau d'études et ingénierie
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Expertise en Études et Conception Technique
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Notre bureau d'études et ingénierie est dédié à la conception de
                solutions techniques sur mesure. Nous combinons expertise,
                créativité et rigueur pour répondre aux besoins spécifiques de
                chaque projet, garantissant des résultats optimaux et conformes
                aux normes les plus strictes.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/steellift-energy-group-etude-ingenierie-industrielle-modeling-maroc.jpg"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Nous offrons des solutions complètes et personnalisées pour
                répondre aux besoins techniques et opérationnels de vos projets.
              </span>
              <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
                Nos Activités en études et ingénierie
              </h5>
            </Col>
          </Row>
          <Services
            grid="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center"
            theme="service-style-03"
            className=""
            data={ActivitiesData}
            animation={fadeIn}
          />
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden">
        <Container>
          <Row className="justify-center">
            <Col
              xl={5}
              sm={8}
              className="text-center mb-24 font-serif sm:mb-12"
            >
              <span className="text-xmd mb-[20px] block text-[#FFC60B] font-medium">
                Processus d'Étude et d'Ingénierie
              </span>
              <h5 className="text-darkgray font-medium inline-block">
                Découvrez les étapes clés de notre processus d'étude et
                d'ingénierie.
              </h5>
            </Col>
            <ProcessStep
              grid="row-cols-1 row-cols-lg-4 row-cols-sm-2 gap-y-10"
              theme="process-step-style-01"
              className=""
              data={ProcessStepData01}
              animation={fadeIn}
            />
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default IngineeringPage;
