import React from "react";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";
import Tab01 from "../Components/Tab/Tab01";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";

// Partials
import EngineeringSection from "./Partial/EngineeringSection";
import DefaultFooter from "./Partial/DefaultFooter";
import DarkHeader from "./Partial/DarkHeader";

// Data

const TabData = [
  {
    tabTitle: "01. CHARPENTE",
    subtitle: "Charpente Métallique",
    title: "Conception et installation de charpentes de haute qualité",
    img: "/assets/img/steellift-energy-group-prestations-charpente-metalique.jpg",
    content:
      "Nous nous spécialisons dans la conception et l'installation de charpentes métalliques de haute qualité, adaptées à vos besoins spécifiques. Nos experts utilisent des matériaux robustes et des techniques avancées pour créer des structures durables et fiables.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/nos-prestations-industrielles/charpente-et-menuiserie",
  },
  {
    tabTitle: "02. TUYAUTERIE",
    subtitle: "Installation de Tuyauterie Industrielle",
    title: "Installation et maintenance de tuyauterie",
    img: "/assets/img/steellift-energy-group-prestations-tuyauterie.jpg",
    content:
      "Nous assurons l'installation et la maintenance de systèmes de tuyauterie industrielle avec une précision exemplaire. Nos services garantissent des installations fiables et conformes aux normes de sécurité, ainsi qu'une maintenance proactive pour optimiser la performance et la longévité de vos installations.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/nos-prestations-industrielles/tuyauterie",
  },
  {
    tabTitle: "03. CHAUDRONNERIE",
    subtitle: "Chaudronnerie Industrielle",
    title: "Fabrication et réparation de structures en métal",
    img: "/assets/img/steellift-energy-group-prestations-chauderonerie_800x717.jpg",
    content:
      "Nous spécialisons dans la chaudronnerie industrielle, offrant des services de fabrication et de réparation de pièces métalliques complexes telles que réservoirs, chaudières, et équipements sur mesure. Grâce à notre expertise technique et à l'utilisation de matériaux de haute qualité, nous assurons des constructions robustes et durables.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/nos-prestations-industrielles/chaudronnerie",
  },
  {
    tabTitle: "04. CONVOYEURS",
    subtitle: "Mécanique de Convoyeurs Industriels",
    title: "Maintenance et optimisation de vos systèmes de convoyeurs",
    img: "/assets/img/steellift-energy-group-prestations-mecanique-convoyeurs-maroc-afrique.jpg",
    content:
      "Nous assurons la maintenance et l'optimisation de vos systèmes de convoyeurs pour garantir une performance maximale et une fiabilité accrue. Notre expertise permet d'améliorer l'efficacité opérationnelle et de prolonger la durée de vie de vos équipements de convoyage.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/nos-prestations-industrielles/convoyeurs",
  },
  {
    tabTitle: "05. USINE PBA",
    subtitle: "Usine de Poteaux Électriques PBA",
    title: "Etudes, Conception et Mise en service ",
    img: "/assets/img/steellift-energy-group-prestations-usine-pba.jpg",
    content:
      "Nous fournissons des usines clé en main pour la fabrication de poteaux en béton armé, assurant une solution complète pour vos besoins industriels. De la conception à l'installation, nous garantissons des équipements performants et adaptés à la production de poteaux robustes et durables.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/nos-prestations-industrielles/usine-fabrication-pba",
  },
];

const OurIndustrialServicesPage = (props) => {
  return (
    <div style={props.style}>
    
      <DarkHeader />

      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-nos-prestations.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={6} lg={7} sm={9} className="relative text-center">
              <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                Des prestations de qualité pour accompagner vos activités
                industrielles
              </h1>
              <h2 className="font-serif text-white -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                Nos Prestations Industrielles
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:bottom-7 xs:bottom-[4.5rem] cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <m.section
        className="py-[130px] relative overflow-visible lg:py-[95px] md:py-[75px] sm:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row className="items-center">
            <Col
              lg={6}
              className="relative mt-[70px] lg:mt-[30px] md:mb-[50px]"
            >
              <div className="relative">
                <Parallax
                  className="lg-no-parallax w-[70%] rounded-[6px] lg:relative lg:!top-[-20px]"
                  speed={0}
                >
                  <div className="absolute top-0 left-0 w-full h-full rounded-[6px] opacity-50 bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] "></div>
                  <img
                    loading="lazy"
                    src="/assets/img/steellift-energy-group-prestations-pba.jpg"
                    className="rounded-[6px] w-full"
                    width="394.8"
                    height="466.34"
                    alt="our-process"
                  />
                </Parallax>
                <Parallax
                  className="lg-no-parallax flex rounded-[6px] justify-center items-center w-[70%] bg-no-repeat absolute bottom-0 right-[15px] lg:!top-0 lg:ml-auto"
                  speed={20}
                >
                  <img
                    loading="lazy"
                    src="/assets/img/steellift-energy-group-prestations-chauderonerie.jpg"
                    className="rounded-[6px] w-full"
                    width="394.8"
                    height="466.34"
                    alt="our-process"
                  />
                </Parallax>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }}>
              <div className="font-serif text-xmd font-medium mb-[30px]">
                <span className="w-[30px] h-[1px] bg-darkgray inline-block align-middle mr-[20px]"></span>
                <span className="text-fastblue inline-block">
                  Que pouvons-nous faire pour votre industrie ?
                </span>
              </div>
              <h5 className="font-serif text-darkgray font-medium mb-[30px] w-[95%]">
                Nos solutions au service de votre industrie
              </h5>
              <p className="w-[95%]">
                <b>Steel Lift Energy Group</b> s'engage à fournir des solutions
                industrielles complètes et personnalisées. Grâce à notre vaste
                expertise et à nos technologies avancées, nous sommes capables
                de répondre aux besoins spécifiques de votre industrie, en
                offrant des services fiables, innovants et efficaces pour
                optimiser vos opérations et assurer votre succès.
              </p>
              <div className="mt-[30px] inline-block lg:mt-[40px] md:mt-[35px] sm:mt-[30px]">
                <Buttons
                  to="/contactez-nous"
                  className="mr-[20px] xs:mr-[8px] font-medium rounded-none font-serif uppercase text-[#fff] hover:text-[#000] btn-slide-filling-right bg-gradient-to-r from-darkgray to-darkgray"
                  size="md"
                  color="#fff"
                  themeColor="#fff"
                  title="Contactez-Nous"
                />
                <Buttons
                  to="/a-propos-de-nous"
                  className="font-medium rounded-none font-serif uppercase hover:text-white bg-transparent btn-slide-right"
                  size="md"
                  color="#232323"
                  themeColor="#232323"
                  title="Qui Sommes-Nous ?"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[130px] lg:py-[95px] md:py-[75px] sm:py-[50px] overflow-hidden"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col lg={11}>
              <Tab01 data={TabData} />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Parallax Scrolling Start */}
      <div className="h-[600px] md:h-[450px] sm:h-[350px] bg-top overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-50, 50]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-prestations-usine-pba-afrique.jpg)`,
          }}
        ></Parallax>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="bg-lightgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <EngineeringSection />
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default OurIndustrialServicesPage;
