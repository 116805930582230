import React from "react";

// Components
import { fadeIn } from "../../Functions/GlobalAnimations";
import Services from "../../Components/Services/Services";

// Libraries
import { Col, Container, Row } from "react-bootstrap";

//Data
const equipementsData = [
  {
    img: "/assets/img/steellift-energy-pont-roulant-mono-poutre.jpg",
    icon: "ti-arrow-right",
    title: "Ponts Roulants",
    content:
      "Les ponts roulants sont conçus pour déplacer des charges lourdes en toute sécurité et efficacité. Idéaux pour les entrepôts, les usines et les chantiers de construction.",
    linkTitle: "En savoir plus",
    link: "/levage/nos-equipements/ponts-roulants",
  },
  {
    img: "/assets/img/steellift-energy-semi-portique.jpg",
    icon: "ti-arrow-right",
    title: "Portique et Semi-Portique",
    content:
      "Offrent des solutions flexibles pour le levage en intérieur et en extérieur, parfaits pour les zones de travail temporaires ou les chantiers nécessitant une mobilité accrue.",
    linkTitle: "En savoir plus",
    link: "/levage/nos-equipements/portiques",
  },
  {
    img: "/assets/img/steellift-energy-potence-mobile.jpg",
    icon: "ti-arrow-right",
    title: "Potences de levage",
    content:
      "Les potences de levage sont idéales pour les opérations de levage de précision dans les ateliers et les zones de travail restreintes. Disponibles en versions murales ou sur colonne.",
    linkTitle: "En savoir plus",
    link: "/levage/nos-equipements/potences",
  },
];

const EquipementsSection = () => {
  return (
    <Container>
      <Row className="justify-center">
        <Col md={6} className="col-12 text-center mb-[4.5rem]">
          <span className="font-serif mb-[10px] block uppercase text-md font-medium">
            Découvrez notre gamme complète d'équipements de levage
          </span>
          <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
            Nos Équipements de Levage
          </h5>
        </Col>
      </Row>
      <Services
        grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 sm:gap-y-[15px]"
        theme="service-style-02"
        className=""
        data={equipementsData}
        animation={fadeIn}
      />
    </Container>
  );
};
export default EquipementsSection;
