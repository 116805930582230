import React from "react";

// Libraries
import { Link } from "react-router-dom";
import { Col, Navbar } from "react-bootstrap";

// Components
import Header, { HeaderNav, Menu } from "../../Components/Header/JmtiHeader";
import SideButtons from "../../Components/SideButtons";

const LightHeader = (props) => {
  return (
    <>
      {/* Header Start */}
      <Header topSpace={{ desktop: true }} type="reverse-scroll">
        <HeaderNav
          fluid="fluid"
          theme="light"
          bg="white"
          menu="light"
          className="px-[35px] py-[0px] md:px-0"
          containerClass="sm:px-0"
        >
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/steel-lift-energy-logo-std.png"
                  data-rjs="/assets/img/steel-lift-energy-logo-std@2x.png"
                  alt="Steel Lift Energy Group"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/steel-lift-energy-logo-std.png"
                  data-rjs="/assets/img/steel-lift-energy-logo-std@2x.png"
                  alt="Steel Lift Energy Group"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/steel-lift-energy-logo-std.png"
                  data-rjs="/assets/img/steel-lift-energy-logo-std@2x.png"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <div className="col-auto hidden order-last md:block">
            <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse className="col-auto px-0 justify-end">
            <Menu {...props} />
          </Navbar.Collapse>
        </HeaderNav>
      </Header>
      <SideButtons />
      {/* Header End */}
    </>
  );
};
export default LightHeader;
