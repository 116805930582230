import React, { useRef } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { AnimatePresence, m } from "framer-motion";
import { Form, Formik } from "formik";

// Components
import SocialIcons from "../Components/SocialIcon/SocialIcons";
import { ContactFormStyle03Schema } from "../Components/Form/FormSchema";
import { Checkbox, Input, TextArea } from "../Components/Form/Form";
import { fadeIn } from "../Functions/GlobalAnimations";
import MessageBox from "../Components/MessageBox/MessageBox";
import Buttons from "../Components/Button/Buttons";
import GoogleMap from "../Components/GoogleMap/GoogleMap";
import { resetForm, sendEmail } from "../Functions/Utilities";
import DefaultFooter from "./Partial/DefaultFooter";
import DarkHeader from "./Partial/DarkHeader";

//Data
const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#00aced",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#007bb6",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in",
  },
];

const ContactUsPage = (props) => {
  const form = useRef(null);
  return (
    <div style={props.style}>
      <DarkHeader />
      {/* Parallax Scrolling Start */}
      <m.div
        className="md:flex md:items-center overflow-hidden relative md:h-[465px] sm:h-[350px] xs:h-[357px]"
        {...fadeIn}
      >
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute left-0 h-[120vh] top-[60px] lg:h-[64vh] lg:top-[-46px] lg:!translate-y-0 md:h-[60vh] md:-top-[30px] sm:top-[-44px] sm:h-[49vh] xs:top-0 xs:h-[40vh] w-full"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-contactez-nous.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-75 top-0 left-0 bg-gradient-to-tr from-[#0039e3] via-[#5e28dd] to-[#8600d4]"></div>
        <Container className="h-full relative">
          <Row className="justify-center h-full">
            <Col
              xl={6}
              lg={7}
              md={10}
              className="text-center flex h-[560px] lg:h-[480px] md:h-[450px] sm:h-[350px] justify-center flex-col font-serif"
            >
              <span className="inline-block opacity-60 mb-[5px] text-white">
                Nous sommes ici pour répondre à toutes vos questions et vous
                assister.
              </span>
              <h1 className="text-white text-[42px] leading-[49px] sm:text-[30px] sm:leading-10 font-medium mb-0">
                Entrons en Contact
              </h1>
            </Col>
          </Row>
        </Container>
      </m.div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="items-end">
            <Col className="col-12 col-lg-6 col-md-4 sm:mb-[30px]">
              <h5 className="font-serif w-[50%] text-darkgray font-medium mb-0 lg:w-[65%] md:w-[95%] xs:w-full">
                Besoin d'aide ou de renseignements ?
              </h5>
            </Col>
            <Col className="col-12 col-lg-6 col-md-8">
              <Row>
                <Col className="col-12 xs:mb-[30px]">
                  <span className="font-serif block text-darkgray font-medium mb-[10px]">
                    Siège social
                  </span>
                  <p className="w-[80%] mb-[5px] lg:w-[90%]">
                    Rue Ahmed Touki, Résidence Ourok, Étage 3 Apptº 13,
                    Casablanca, Maroc
                  </p>
                  <span className="block mb-[10px]">
                    Tél: (+212) 697 04 31 98
                  </span>
                  <a
                    href="https://www.google.com/maps?ll=-37.817214,144.955925&amp;z=16&amp;t=m&amp;hl=en-US&amp;gl=IN&amp;mapclient=embed&amp;cid=13153204942596594449"
                    target="_blank"
                    rel="noreferrer"
                    className="uppercase text-sm text-darkgray font-medium border-b hover:border-fastblue border-black hover:text-fastblue"
                  >
                    Voir sur google map
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
              <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">
                Pour toute information ou assistance, laissez-nous un message.
              </span>
              <h4 className="font-serif font-semibold text-darkgray">
                Prenez Contact avec Nous
              </h4>
            </Col>
            <Col className="col-xl-10 col-sm-12 col-xs-12">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  comment: "",
                  terms_condition: false,
                }}
                validationSchema={ContactFormStyle03Schema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  const response = await sendEmail(values);
                  response.status === "success" && resetForm(actions);
                }}
              >
                {({ isSubmitting, status }) => (
                  <Form ref={form}>
                    <Row className="row-cols-1 row-cols-md-2">
                      <Col className="mb-16 sm:mb-[25px]">
                        <Input
                          showErrorMsg={false}
                          type="text"
                          name="name"
                          className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          labelClass="mb-[25px]"
                          placeholder="Your name"
                        />
                        <Input
                          showErrorMsg={false}
                          type="email"
                          name="email"
                          className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                          labelClass="mb-[25px]"
                          placeholder="Your email address"
                        />
                        <Input
                          showErrorMsg={false}
                          type="tel"
                          name="phone"
                          className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                          placeholder="Mobile number"
                        />
                      </Col>
                      <Col className="mb-16 sm:mb-[20px]">
                        <TextArea
                          className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                          rows="6"
                          name="comment"
                          placeholder="Your message"
                        ></TextArea>
                      </Col>
                      <Col className="text-left sm:mb-[20px]">
                        <Checkbox
                          type="checkbox"
                          name="terms_condition"
                          className="inline-block mt-[4px]"
                          labelClass="flex items-start"
                        >
                          <span className="ml-[10px] text-sm inline-block w-[85%]">
                            J'accepte les termes et conditions et je comprends
                            que mes données seront conservées en toute sécurité
                            conformément à
                            <Link
                              aria-label="checkbox"
                              to="/politique-de-confidentialite"
                              target="_blank"
                              className="text-darkgray underline hover:text-fastblue"
                            >
                              {" "}
                              la politique de confidentialité.
                            </Link>
                            .
                          </span>
                        </Checkbox>
                      </Col>
                      <Col className="text-right sm:text-center">
                        <Buttons
                          type="submit"
                          className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${
                            isSubmitting ? " loading" : ""
                          }`}
                          themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                          size="md"
                          color="#fff"
                          title="Send Message"
                        />
                      </Col>
                    </Row>
                    <AnimatePresence>
                      {status && (
                        <Row>
                          <Col xs={12}>
                            <div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                            >
                              <MessageBox
                                className="mt-[20px] py-[10px]"
                                theme="message-box01"
                                variant="success"
                                message="Your message has been sent successfully!"
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                    </AnimatePresence>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-lightgray py-[100px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="justify-center items-center">
            <Col
              xs={12}
              xl={3}
              lg={4}
              md={5}
              sm={6}
              className="lg:flex lg:justify-end xs:mb-[20px] xs:justify-center"
            >
              <span className="font-serif font-medium text-xlg text-darkgray inline-block -tracking-[.5px] lg:text-lg">
                Restons Connectés
              </span>
            </Col>
            <Col xs={12} md={2} className="block sm:hidden">
              <span className="w-full h-[1px] inline-block bg-mediumgray"></span>
            </Col>
            <Col xs={12} xl={3} lg={4} md={5} sm={6}>
              <SocialIcons
                theme="social-icon-style-01"
                className="justify-left xs:justify-center xs:text-center"
                size="sm"
                iconColor="dark"
                data={SocialIconsData}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section Start */}

      {/* Section Start */}
      <section>
        <GoogleMap
          className="grayscale h-[600px] p-0 md:h-[450px] xs:h-[300px]"
          location="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d425400.73110398767!2d-7.618409000000001!3d33.593803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d29b6e829e0d%3A0xfaa1aedd69fe812a!2sRue%20Ahmed%20Touki%2C%20Casablanca%2020250%2C%20Morocco!5e0!3m2!1sen!2sus!4v1716931512166!5m2!1sen!2sus"
        />
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default ContactUsPage;
