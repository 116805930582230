import React from "react";

// Components
import { fadeIn, fadeInRight } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";

// Partials
import EngineeringSection from "./Partial/EngineeringSection";
import PrestationsSection from "./Partial/PrestationsSection";
import AfricaFooter from "./Partial/AfricaFooter";
import ContactSectionCTA from "./Partial/ContactSectionCTA";
import DarkHeader from "./Partial/DarkHeader";


const HomeAfricaPage = (props) => {
  return (
    <div style={props.style}>
      <DarkHeader />

      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-africa-usine-pba.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={6} lg={7} sm={9} className="relative text-center">
              <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                Fourniture et Installation d'Usine pour Poteaux en Béton Armé
              </h1>
              <h2 className="font-serif text-white -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                Usine clés en main
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:bottom-7 xs:bottom-[4.5rem] cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section
        id="about"
        className="bg-lightgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]"
      >
        <Container>
          <Row className="font-serif">
            <Col lg={4} className="pe-lg-0 flex md:mb-[30px]">
              <div
                className="w-full md:h-[700px] sm:h-[550px] xs:h-[450px] cover-background"
                style={{
                  backgroundImage:
                    "url('/assets/img/steellift-energy-group-usine-fabrication-pba-afrique-qui-sommes-nous.jpg') ",
                }}
              ></div>
            </Col>
            <Col
              lg={4}
              md={6}
              className="ps-lg-0 flex items-center sm:mb-[30px]"
            >
              <div className="justify-center h-full w-full flex flex-col items-start bg-darkgray px-[5.5rem] lg:px-[3rem] md:p-16">
                <span className="text-xlg lg:text-lg lg:leading-[26px] font-medium text-white mb-[20px] block">
                  Spécialisés dans la fourniture et l'installation d'usine de
                  fabrication de poteaux en béton armé
                </span>
                <p className="text-white font-sans opacity-70 mb-[20px] xs:mb-[15px]">
                  Nous sommes spécialisés dans la fourniture et l'installation
                  clé en main d'usines pour la fabrication de poteaux en béton
                  armé, avec plusieurs références réussies en Afrique de
                  l'Ouest. Notre approche intégrée assure une mise en œuvre
                  rapide et efficace, garantissant des installations de haute
                  qualité adaptées à vos besoins spécifiques.
                </p>
                <Buttons
                  href="/a-propos-de-nous"
                  className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-[#fff] hover:text-[#fff]"
                  color="#fff"
                  title="A propos de nous"
                />
              </div>
            </Col>
            <Col lg={4} md={6} className="flex flex-col pr-0">
              <img
                src="/assets/img/steellift-energy-group-africa.jpg"
                alt="about us"
                className="sm:w-full"
              />
              <div className="bg-white px-[3.5rem] py-[3rem] h-full lg:p-8 sm:p-16">
                <span className="text-darkgray font-medium mb-[10px] block">
                  STEEL LIFT ENERGY GROUP
                </span>
                <p className="font-sans">
                  Nous offrons une solution complète pour la fourniture et
                  l'installation d'usines dédiées à la fabrication de poteaux en
                  béton armé. De la conception à l'achèvement, nous assurons une
                  installation clé en main, garantissant une mise en service
                  rapide et efficace pour une production optimale.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-darkgray overflow-hidden">
        <Container fluid>
          <Row>
            <m.div
              className="col-lg-6 cover-background md:h-[550px] sm:h-[400px] xs:h-[300px]"
              {...fadeInRight}
              style={{
                backgroundImage: `url('/assets/img/steellift-energy-group-usine-fabrication-pba-afrique.jpg')`,
              }}
            ></m.div>
            <m.div
              className="col-lg-6 py-[119px] px-[147px] xl:p-[7.5rem] lg:p-[4.5rem] md:p-[5.5rem] xs:px-20"
              {...fadeIn}
            >
              <h2 className="heading-4 font-serif font-semibold text-white -tracking-[1px] w-[65%] mb-10 xl:w-full">
                Notre solutions complète pour la fabrication de poteaux en béton
                armé
              </h2>
              <p className="w-[60%] text-[#828282] mb-[25px] xl:w-full">
                Nous offrons une solution complète pour la fabrication de
                poteaux en béton armé, incluant la fourniture, l'installation et
                le démarrage d'usines clé en main. Notre expertise garantit une
                installation efficace, des équipements de haute qualité, et un
                soutien complet tout au long du processus pour assurer une
                production optimale.
              </p>
              <Buttons
                ariaLabel="our-services"
                to="/nos-prestations-industrielles/usine-fabrication-pba"
                className="btn-fill mt-[20px] font-medium font-serif tracking-[1px] rounded-none uppercase"
                themeColor="#fff"
                color="#232323"
                size="lg"
                title="Découvrez Plus"
              />
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-ligthgray py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <EngineeringSection />
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-lightgray overflow-hidden"
        {...fadeIn}
      >
        <PrestationsSection />
      </m.section>
      {/* Section End */}

      {/* Section CTA Start */}
      <ContactSectionCTA />
      {/* Section End */}

      {/* Footer Section */}
      <AfricaFooter />
      {/* Footer End */}
    </div>
  );
};

export default HomeAfricaPage;
