import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import OurServicesPage from "./Pages/OurServicesPage";
import OurIndustrialServicesPage from "./Pages/OurIndustrialServicesPage";
import IngineeringPage from "./Pages/IngineeringPage";
import ContactUsPage from "./Pages/ContactUsPage";
import EquipmentsPage from "./Pages/EquipmentsPage";
import PontRoulantPage from "./Pages/PontRoulantPage";
import PortiquePage from "./Pages/PortiquePage";
import PotencePage from "./Pages/PotencePage";
import CharpentePage from "./Pages/CharpentePage";
import TuyauteriePage from "./Pages/TuyauteriePage";
import ChauderoneriePage from "./Pages/ChauderoneriePage";
import ConvoyeurPage from "./Pages/ConvoyeurPage";
import UsinePbaPage from "./Pages/UsinePbaPage";
import NotFoundPage from "./Pages/404";
import HomeAfricaPage from "./Pages/HomeAfricaPage";

// Home
const HomePage = lazy(() => import("./Pages/HomePage"));
const AboutUsPage = lazy(() => import("./Pages/AboutUsPage"));
const OurSolutionsPage = lazy(() => import("./Pages/OurSolutionsPage"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* Homepage */}
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#3452ff" }} />}
                  />
                  <Route
                    path="/steel-lift-afrique"
                    element={<HomeAfricaPage style={{ "--base-color": "#3452ff" }} />}
                  />

                  {/* Main Pages */}
                  <Route
                    path="/a-propos-de-nous"
                    element={
                      <AboutUsPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/levage"
                    element={
                      <OurSolutionsPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/nos-prestations-industrielles"
                    element={
                      <OurIndustrialServicesPage
                        style={{ "--base-color": "#3452ff" }}
                      />
                    }
                  />
                  <Route
                    path="/etudes-et-ingenierie"
                    element={
                      <IngineeringPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/contactez-nous"
                    element={
                      <ContactUsPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />

                  {/* Nos Solutions Pages */}
                  <Route
                    path="/levage/nos-services"
                    element={
                      <OurServicesPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/levage/nos-equipements"
                    element={
                      <EquipmentsPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/levage/nos-equipements/ponts-roulants"
                    element={
                      <PontRoulantPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/levage/nos-equipements/portiques"
                    element={
                      <PortiquePage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/levage/nos-equipements/potences"
                    element={
                      <PotencePage style={{ "--base-color": "#3452ff" }} />
                    }
                  />

                  {/* Nos Prestations Pages */}
                  <Route
                    path="/nos-prestations-industrielles/charpente-et-menuiserie"
                    element={
                      <CharpentePage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/nos-prestations-industrielles/tuyauterie"
                    element={
                      <TuyauteriePage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/nos-prestations-industrielles/chaudronnerie"
                    element={
                      <ChauderoneriePage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/nos-prestations-industrielles/convoyeurs"
                    element={
                      <ConvoyeurPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />
                  <Route
                    path="/nos-prestations-industrielles/usine-fabrication-pba"
                    element={
                      <UsinePbaPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />

                  {/* 404 */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
